import React from "react";
import { Navbar } from "react-bootstrap";
import simonsLogo from "../../Components/new_logo.jpg";
import "./Footer.css";

function Footer() {
  return (
    <Navbar className="footer">
      <Navbar.Toggle />
      <Navbar.Brand href="#home">
        <img
          src={simonsLogo}
          id="footer-logo"
          width="100"
          height="100"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text></Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Footer;
