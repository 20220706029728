import React from "react";
import "../HomePage.css";
import { Button } from "react-bootstrap";
import Scissors from "../../../Components/barber-shop.png";

function Services() {
  return (
    <div className="services-section" id="services">
      <h1 className="section-title">SERVICES</h1>
      <div
        style={{ borderTop: "2px solid #a5770a", width: "5%" }}
        className="gold-seperator"
      ></div>

      <div className="row" style={{ width: "100%" }}>
        <div className="col-md-6 col-sm-6 col-xs-10">
          <img src={Scissors} alt="scissors-img" id="scissors-image" />
        </div>
        <div
          className="col-md-6 col-sm-6 col-xs-10"
          style={{
            paddingTop: "2%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="row" style={{ justifyContent: "center" }}>
            <table className="table" id="services-table">
              <tbody>
                <tr>
                  <td colSpan="2">Hair Cut and Style</td>
                  <td className="price">£45</td>
                </tr>
                <tr>
                  <td colSpan="2">Back and Sides Refresher</td>
                  <td className="price">£35</td>
                </tr>
                <tr>
                  <td colSpan="2">Full Clipper Cut</td>
                  <td className="price">£30</td>
                </tr>
                <tr>
                  <td colSpan="2">Skin Fade</td>
                  <td className="price">£50</td>
                </tr>
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontStyle: "italic",
                    marginTop: "0.75rem",
                  }}
                >
                  * All prices from
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col" id="button-columns">
          <Button
            className="services-button"
            variant="primary"
            href="/servicesInfo"
          >
            View All Services
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Services;
