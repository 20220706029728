import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "../HomePage.css";

function Testimonials() {
  return (
    <div className="testimonial-row" id="testimonials">
      <h1 style={{ color: "black" }} className="section-title">
        TESTIMONIALS
      </h1>
      <div
        style={{ borderTop: "2px solid #a5770a", width: "5%" }}
        className="gold-seperator"
      ></div>
      <Carousel>
        <Carousel.Item>
          <div className="carousel-styling">
            <h2 className="testimonial-title">Daniel &amp; Russell</h2>
            <br />
            <p>
              "We travel all the way from Fulham to have Simon cut out hair,
              definitely worth the journey - best cut I've ever had"
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-styling">
            <h2 className="testimonial-title">Gourav Sharma</h2>
            <br />
            <p>"Absolutely top-notch! A brilliant place to get a haircut."</p>
            <p>
              "After being fed up with my local barbers, I wanted to try one in
              Central London for the first time. I booked a restyle back in
              January with Simon. You can tell that he's skilled, friendly and
              pays attention to what he’s doing. He listens to how you would
              like it done and actually checks with you at key stages to ensure
              you're happy. He was also able to advise on what products to use
              for my hair. They have their own which they sell and I ended up
              buying the 'Define Créme' which I’ve been using for about a month.
              It's a great quality paste with a good amount of hold that works
              well with my straight hair. I use nothing else. Will be back
              again!"
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-styling">
            <h2 className="testimonial-title">Jonathon Quinn</h2>
            <br />
            <p>
              "Been seeing Simon since 2013, best barber in London hands down.
              Tried others as I don’t live locally and nothing compares. Well
              worth the journey into London for top quality service and great
              haircut!"
            </p>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-styling">
            <h2 className="testimonial-title">Oliver Carey</h2>
            <br />
            <p>
              "After plenty of trial and error of going to different Barbers, I
              finally came across Barber Shop of Mayfair. With a lot of hope and
              with a low expectation from all the previous disasters, I booked
              an appointment with Simon to cut my hair... I was absolutely blown
              away and more so relieved that I had a proper haircut! I gave my
              input and he knew exactly what I wanted, Simon isn't one of those
              barbers who gives you what they think is best, he listens and
              takes on board what you want. He's a perfectionist and will make
              sure you leave with a smile on your face. I've never looked back
              since and will continue seeing Simon every 2 weeks!"
            </p>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="carousel-styling">
            <h2 className="testimonial-title">Jamie Prescott</h2>
            <br />
            <p>
              "Big fan of this place. Simon’s the man to go for - always
              consistent and high quality."
            </p>
          </div>
        </Carousel.Item>

        <Carousel.Item className="carousel-item">
          <div className="carousel-styling">
            <h2 className="testimonial-title">Kunul Bhagalia</h2>
            <br />
            <p>
              "Been coming to Simon at the barber shop for 4+ years now.
              Wouldn’t trust anyone else with haircut!"
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Testimonials;
