import React from "react";
import { Button } from "react-bootstrap";
import moustache from "../../mustache.png";
import "../HomePage.css";

function ServicesInfo() {
  return (
    <>
      <div className="row2">
        <h1 className="bg-text">SERVICES</h1>
        <div className="gold-seperator"></div>

        <Button className="btn book-now-button2" href="/bookaservice">
          Book An Appointment
        </Button>
      </div>

      <div
        className="row"
        id="moustache-row"
        style={{ justifyContent: "center" }}
      >
        <img src={moustache} alt="moustache-icon" id="moustache-icon" />
      </div>

      <div className="row" id="serviceInfo-row">
        <h1 className="serviceInfo-title">
          Haircut and Style
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £45</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">40 minutes</p>
          You will experience precision cutting and styling at its finest, this
          treatment offers a consultation, a shampoo and condition. The
          experience concludes with your hair being styled with our very own
          products and to top it off a hot towel infused with an aromatic scent.
        </span>

        <h1 className="serviceInfo-title">
          Clippers Back and Side, Scissors on Top{" "}
          <span style={{ fontStyle: "italic" }}>(without wash)</span>
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £40</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">30 minutes</p>
          The never out of fashion crew cut. This is performed by using clippers
          on the back and sides of your head and scissors on top. Styled at the
          end of your service with a product to suit your look.
        </span>

        <h1 className="serviceInfo-title">
          Skin Fade
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £50</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">45 minutes</p>
          Years of recent have seen this style explode onto the scene. Shortest
          you can go on the sides. A foil shaver will be used to ensure that
          silky smooth finish. Lots of different variations of this one so sit
          back and enjoy the creativity.
        </span>

        <h1 className="serviceInfo-title">
          Back and Sides Refresher
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £35</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">30 minutes</p>
          Perfect for inbetween haircuts when the sides need attention but your
          happy with the top. This is a service for those who want to stay sharp
          and short all month long. This is not a full haircut. This is a
          service to refresh the sides only.
        </span>

        <h1 className="serviceInfo-title">
          Full Clipper Cut
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £30</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">20 minutes</p>
          Using clippers only, to achieve a short all over even clipper cut.
        </span>

        <h1 className="serviceInfo-title">
          Restyle
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £55</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">45 minutes</p>
          This is for people who fancy a total change of style, from long hair
          to short hair and everything in between.
          <br /> We hold a detailed consultation with you to see what you like
          and dislike about your hair and what will suit your face shape. A
          complimentary hair shampoo and condition will be provided.
          <br />
          If you are looking for a transformation, a Restyle is for you.
        </span>

        <h1 className="serviceInfo-title">
          Children under 16
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £40</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">30 minutes</p>
          Your son will experience the highest quality of haircut and
          experience. Complementary hairwash and style to finish and always an
          explanation to help teach how to maintain this style throughout the
          month.
        </span>

        <h1 className="serviceInfo-title">
          Colour Camo / Blend
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £40</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">30 minutes</p>
          The ever popular service for the salt and peppered man. These products
          are designed specifically for the use of men only. It targets only the
          grey hair and covers 90% of the greys in 5minutes, yes only 5 minutes,
          giving you a natural finish. Instantly knocks years off you without
          showing you have had anything done. What every man wants.
        </span>

        <h1 className="serviceInfo-title">
          Beard Trim with Open Razor
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £35</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">30 minutes</p>
          First we bring the length down on your beard with either scissors,
          clippers or a combination of both, followed up by the outlines being
          shaped up with a cut throat razor finished off with a relaxing scented
          hot towel.
        </span>

        <h1 className="serviceInfo-title">
          Beard Trim with Clippers
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £28</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">20 minutes</p>
          Length of beard or moustache being trimmed down by Scissors, Clipper
          or combination of both with a scented hot towel to finish the service.
        </span>

        <h1 className="serviceInfo-title">
          Luxury Hot Towel Shave
          <span
            style={{
              color: "#a5770a",
              fontSize: "1.25rem",
              fontStyle: "italic",
            }}
          >
            {" "}
            - from
          </span>
          <span style={{ color: "#a5770a" }}> £60</span>
        </h1>
        <span className="service-desc">
          <p className="service-time">45 minutes</p>
          Our shaves start with a consultation and skin analysis to find out any
          allergies or sensitivities.
          <br />
          We then go on to the shave itself, starting with relaxing pads placed
          over both eyes, we then use oils to soften your stubble and prepare
          your skin for the shave. <br />
          Using scented hot towels to aid with your relaxation and opening of
          pores, you will be expertly shaved and moisturized for the optimum
          smooth finish.
          <br />
          {/* Our shaves start with a consultation and skin analysis to find out any
          allergies or sensitivities. We then go on to the shave itself,
          starting with relaxing pads placed over both eyes, we then use oils to
          soften your stubble and prepare your skin for the shave. <br />
          Using scented hot towels to aid with your relaxation and opening of
          pores, you will be expertly shaved and moisturized for the optimum
          smooth finish. */}
        </span>
      </div>

      {/* <div className="row" id="appointment-button" style={{justifyContent: 'center', paddingBottom: '2%'}}>
                    <Button className="services-button" variant="primary" href="/bookaservice">Make An Appointment</Button>
            </div> */}
    </>
  );
}

export default ServicesInfo;
