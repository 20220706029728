import React from "react";
import "./HomePage.css";
import Contact from "./contact/Contact";
import Testimonials from "./testimonials/Testimonials";
import Services from "./services/Services";
import Locations from "./location/Locations";
import SimonLogo from "../../Components/new_logo.jpg";
import { Button } from "react-bootstrap";

function HomePage() {
  return (
    <>
      <div className="row1">
        <img src={SimonLogo} alt="simoncablehairlogo" id="home" />

        <Button className="btn book-now-button2" href="/bookaservice">
          Book an Appointment
        </Button>
      </div>
      <Contact />
      <Services />
      <Locations />
      <Testimonials />
    </>
  );
}

export default HomePage;
