import React from 'react';
import '../HomePage.css';

function BookServices() {
    return(
        <>
        <div className="container">
            <div className="row">
                <div id="nearcut" data-domain="https://iframe-12260.nearcut.com"></div>
            </div>
        </div>
        </>
    )
}

export default BookServices;