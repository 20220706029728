import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "../Components/MainPage/HomePage";
import About from "../Components/MainPage/about/About";
import ServicesInfo from "./MainPage/services/ServicesInfo";
import BookServices from "./MainPage/services/BookServices";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/servicesInfo" component={ServicesInfo} />
        <Route path="/bookaservice" component={BookServices} />
        <Route path="/about" component={About} />
      </Switch>
    </Router>
  );
}

export default Routes;
