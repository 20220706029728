import React from "react";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import "./Navbar.css";

function NavBar() {
  return (
    <Navbar collapseOnSelect expand="sm" className="navBar-layout">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav.Link className="link" href="/#home">
          Home
        </Nav.Link>
        <Nav.Link className="link" href="/about">
          About
        </Nav.Link>
        <Nav.Link className="link" href="/#services">
          Services
        </Nav.Link>
        <Nav.Link className="link" href="/#contact">
          Contact
        </Nav.Link>
        <Nav.Link className="link" href="/#testimonials">
          Testimonials
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
