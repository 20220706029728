import React, { Component } from 'react';
import { BrowserRouter as Router} from 'react-router-dom'
import Navbar from './Components/Layout/Navbar'
import Footer from './Components/Layout/Footer'
import Routes from './Components/Routes';

class App extends Component {
  // constructor(props) {
  //   super(props);
  

  render() {
    return (
      <div>
        <Router>
          <Navbar />
            <Routes />
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;