import React from "react";
import { Button } from "react-bootstrap";
import SimonHeadShot from "../../../Components/simon-pic.jpg";
import ShinaHeadShot from "../../../Components/ShinaImage2.png";
import "../HomePage.css";

function About() {
  return (
    <>
      <div className="row2">
        <h1 className="bg-text">ABOUT</h1>
        <div className="gold-seperator"></div>
        <Button className="btn book-now-button2" href="/bookaservice">
          Book An Appointment
        </Button>
      </div>

      <div className="row about-simon">
        <div className="row about-header">
          <h1>About Us</h1>
        </div>

        <div className="row about-simon-text">
          <div className="row">
            <p id="about-text">
              Barbershop of Mayfair has been providing the highest quality of
              grooming service to the men of Mayfair since 2012. Located at the
              Park Street entrance of the exquisite JW Marriot Grosvenor House
              Hotel it is a gem you will want to find… <br />
              <br />
              Barbershop of Mayfair has a focus on top quality haircuts, beard
              trims and traditional hot towel shaves. We are dedicated to
              providing the highest quality of customer service and you will
              leave wondering why you haven’t visited before. Working hard
              towards becoming The Best Barbershop in London.
            </p>
          </div>
        </div>

        <div className="row about-header">
          <h1>
            Simon{" "}
            <span style={{ fontSize: "1.5rem", fontStyle: "italic" }}>
              (Senior Stylist)
            </span>
          </h1>
        </div>

        <p id="simon-bio-text">
          Simon the owner of Barbershop of Mayfair has worked in the area since
          2012 and with his friendly demeanour he has built a very loyal
          following over the years. Simon’s career started in his home country
          of Ireland where he then moved to London in 2011. Early in Simon’s
          career he was very lucky to have worked with some of the best in the
          business from all around the world. As well as passing on his
          knowledge he has soaked up so many tricks of the trade to make him the
          barber he is today. If you are looking for the full package, look no
          further.
        </p>

        <p id="simon-bio-text">
          Simon is an expert in all aspects of Men’s Barbering. Central London
          is the epitome of Diversity so Simon has everything in his locker,
          from Long Scissor Cuts to Short Tight Sharp Skin fades, from taming
          unruly wavey hair to giving super straight hair that extra dimension.
          Simon is a big fan of the timeless Classics but when given the artist
          license he can produce the most creative cuts in the industry.
        </p>

        <p id="simon-bio-text">
          Simon is also a qualified Hair Loss Adviser. He can also help with
          recommending products and treatments for Scalp Issues. Simon is also
          closely linked with the leading surgeons of Harley Street’s Hair Loss
          clinics where he can refer you for free consultations. It’s safe to
          say Simon is qualified to look after all of your Hair Needs.
        </p>
        <div className="row simon-headshot-row">
          <img
            src={SimonHeadShot}
            alt="babershopbymayfair-simon"
            id="simon-headshot"
          />
        </div>

        <div className="row about-header">
          <h1>
            Shina{" "}
            <span style={{ fontSize: "1.5rem", fontStyle: "italic" }}>
              (Senior Stylist)
            </span>
          </h1>
        </div>

        <p id="simon-bio-text">
          Hello, I'm Shina, a seasoned barber with over 20 years of experience
          in the art of grooming. Originally hailing from Persia, I bring a rich
          heritage and a deep understanding of traditional techniques to my
          craft. Known for providing the best shave in town, I take immense
          pride in delivering an unparalleled grooming experience to each of my
          clients. My specialty lies in scissor cuts, where precision and
          artistry converge to create personalized styles that perfectly suit
          your features and preferences. Whether you're looking for a clean,
          classic look or a modern, edgy style, my expertise ensures you'll
          leave my chair feeling confident and looking sharp.
        </p>

        <p id="simon-bio-text">
          At my barbershop, you'll find a warm and welcoming atmosphere, where
          every visit is more than just a haircut—it's a ritual. I invite you to
          experience the skill and dedication that have made me a trusted name
          in the community for over two decades.
        </p>

        <p id="simon-bio-text">
          I look forward to welcoming you to our shop and helping you achieve
          the perfect look.
        </p>

        <div className="row simon-headshot-row">
          <img
            src={ShinaHeadShot}
            alt="babershopbymayfair-simon"
            id="shina-headshot"
          />
        </div>

        <div className="row about-services-row">
          <div className="row about-header">
            <h1 style={{ paddingLeft: "10px" }}>About Our Services</h1>
          </div>

          <div className="row about-services-text">
            <p id="about-text">
              Every barber service starts with an in-depth consultation which
              helps us understand your needs and wants which helps us deliver
              the perfect barbering service, so you can look and feel fantastic.
              Not sure what you want to achieve? Book an appointment for a
              Restyle so we can have a chat to see what would best suit your
              face shape and lifestyle.
            </p>

            <p id="about-text">
              Barbershop of Mayfair services include the Sharpest Men’s
              Haircuts, Finest Wet Shaves, and Best Beard Trims in the West End
              of London. Don’t believe us? Book an appointment to find out. It’s
              an experience that seamlessly blends all the techniques of
              traditional barbering with modern day men’s hairdressing to create
              the very best barbering experience. One of the first things that
              people notice when they meet you is your hairstyle. Who doesn’t
              want to leave a good impression? With our Quality services, we
              help all men look and feel as good as possible so they can meet
              all their personal ambitions.
            </p>

            <p id="about-text">
              Why not have a relaxing manicure with your Haircut or Beard Trim?
              Relax in the chair while we look after your hair needs and a nail
              technician cleans, cuts and polishes those finger nails. Leave
              feeling the best version of you.
            </p>

            <p id="about-text">
              Barbershop of Mayfair includes quality Men’s Haircuts, Beard
              Styling, and Luxury Wet Shaves, styled with a variety of market
              leaders in Men’s Hair Products that can be purchased in-store.
              From classic techniques to modern men’s hairstyles, my refined
              barber services adds an element of relaxation to the clients’
              life. Why shouldn’t we as men be pampered too?
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
